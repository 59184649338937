import { ORDER_STATUS_CONSTANTS } from '@/constants/constants.ts'

import { ENUM_ACTION_TYPES } from '@/enums/Enums'

import { getDefaultExtendedReservationStructure, isTable } from '@/utils/typeGuardUtils'

import {
    ApplicationAction,
    ExtendedReservationT,
    ExtendedTableT,
    OrdersDashboardState,
} from '@/types/globalTypes'

const ordersReducer = (
    prevState: OrdersDashboardState,
    action: ApplicationAction
): OrdersDashboardState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.INSERT_ORDER: {
            const { newOrder } = action.payload

            const orderExists = prevState.orders.some((order) => order.id === newOrder.id)
            if (orderExists) {
                return prevState
            }

            // Find the associated reservation
            const reservationIndex = prevState.reservations.findIndex(
                (reservation) => reservation.id === newOrder.reservation_id
            )

            // Create a new order object with or without a linked reservation
            const orderWithReservation =
                reservationIndex !== -1
                    ? { ...newOrder, reservation: prevState.reservations[reservationIndex] }
                    : { ...newOrder, reservation: null }
            const updatedOrders = [...prevState.orders, orderWithReservation]

            if (reservationIndex !== -1) {
                const updatedReservations = prevState.reservations.map((reservation) => {
                    if (newOrder && reservation.id === newOrder.reservation_id) {
                        const ordersForThisReservation = updatedOrders.filter(
                            (order) => order.reservation_id === reservation.id
                        )
                        const hasPendingOrder =
                            ordersForThisReservation.some(
                                (order) => order.status === ORDER_STATUS_CONSTANTS.PENDING
                            ) || orderWithReservation.status === ORDER_STATUS_CONSTANTS.PENDING

                        return { ...reservation, hasPendingOrder }
                    } else {
                        return reservation
                    }
                })

                const updatedTables = prevState.tablesData.map((table) => {
                    const tableReservationData = updatedReservations.find(
                        (reservation) =>
                            (reservation.table &&
                                isTable(reservation.table) &&
                                reservation.table.id === table.id) ||
                            reservation.id === table.static_reservation_id
                    )

                    if (tableReservationData) {
                        return {
                            ...table,
                            reservation: tableReservationData,
                        }
                    } else {
                        return {
                            ...table,
                            reservation: getDefaultExtendedReservationStructure(),
                        }
                    }
                }) as ExtendedTableT[]
                return {
                    ...prevState,
                    orders: updatedOrders,
                    reservations: updatedReservations,
                    tablesData: updatedTables,
                }
            }

            return { ...prevState, orders: [...prevState.orders, orderWithReservation] }
        }
        case ENUM_ACTION_TYPES.UPDATE_ORDER: {
            const { updatedOrder } = action.payload
            const orderIndex = prevState.orders.findIndex((order) => order.id === updatedOrder.id)

            if (orderIndex === -1) return prevState // Order not found

            const reservationIndex = prevState.reservations.findIndex(
                (reservation) => reservation.id === updatedOrder.reservation_id
            )
            const orderWithReservation =
                reservationIndex !== -1
                    ? { ...updatedOrder, reservation: prevState.reservations[reservationIndex] }
                    : { ...updatedOrder, reservation: null }

            // Create a new array with the updated order
            const updatedOrders = [...prevState.orders]
            updatedOrders[orderIndex] = orderWithReservation

            const updatedReservations = prevState.reservations.map((reservation) => {
                if (updatedOrder && reservation.id === updatedOrder.reservation_id) {
                    const ordersForThisReservation = updatedOrders.filter(
                        (order) => order.reservation_id === reservation.id
                    )
                    const hasPendingOrder =
                        ordersForThisReservation.some(
                            (order) => order.status === ORDER_STATUS_CONSTANTS.PENDING
                        ) || orderWithReservation.status === ORDER_STATUS_CONSTANTS.PENDING

                    return { ...reservation, hasPendingOrder }
                } else {
                    return reservation
                }
            })

            const updatedTables = prevState.tablesData.map((table) => {
                const tableReservationData = updatedReservations.find(
                    (reservation) =>
                        (
                        table.static_reservation_id === reservation.id || reservation.table_id === table.id)  )

                if (tableReservationData) {
                    return {
                        ...table,
                        reservation: tableReservationData,
                    }
                } else {
                    return {
                        ...table,
                        reservation: getDefaultExtendedReservationStructure(),
                    }
                }
            }) as ExtendedTableT[]

            return {
                ...prevState,
                orders: updatedOrders,
                reservations: updatedReservations,
                tablesData: updatedTables,
            }
        }

        case ENUM_ACTION_TYPES.DELETE_ORDER: {
            const { deletedOrder } = action.payload

            const updatedOrders = prevState.orders.filter((order) => order.id !== deletedOrder.id)
            const reservationIndex = prevState.reservations.findIndex(
                (reservation) => reservation.id === deletedOrder.reservation_id
            )

            if (reservationIndex !== -1) {
                const updatedReservations = prevState.reservations.map((reservation) => {
                    if (deletedOrder && reservation.id === deletedOrder.reservation_id) {
                        const ordersForThisReservation = prevState.orders.filter(
                            (order) => order.reservation_id === reservation.id
                        )
                        const hasPendingOrder = ordersForThisReservation.some(
                            (order) => order.status === ORDER_STATUS_CONSTANTS.PENDING
                        )

                        return { ...reservation, hasPendingOrder }
                    } else {
                        return reservation
                    }
                })
                return { ...prevState, orders: updatedOrders, reservations: updatedReservations }
            }
            return { ...prevState, orders: updatedOrders }
        }

        case ENUM_ACTION_TYPES.ASSIGN_TABLE_TO_WAITER: {
            const { waiterId, table, waiterName, orderIdsForTable } = action.payload
            const tableId = table.id

            let updatedOrders = [...prevState.orders]
            if (orderIdsForTable.length > 0) {
                updatedOrders = updatedOrders.map((order) => {
                    if (orderIdsForTable.includes(order.id)) {
                        return { ...order, waiter: waiterId, waiter_name: waiterName }
                    }
                    return order
                })
            }

            // Update Reservations
            const updatedReservations = prevState.reservations.map((reservation) => {
                if (
                    (reservation.table &&
                        isTable(reservation.table) &&
                        reservation.table.id === tableId) ||
                    table.static_reservation_id === reservation.id
                ) {
                    return { ...reservation, table: { ...table, waiter: waiterId } }
                }
                return reservation
            }) as ExtendedReservationT[]

            // Update Tables Data
            const updatedTables = prevState.tablesData.map((table) => {
                if (table.id === tableId) {
                    return { ...table, waiter: waiterId }
                }
                return table
            })

            return {
                ...prevState,
                orders: updatedOrders,
                reservations: updatedReservations,
                tablesData: updatedTables,
            }
        }

        // Add other action types as needed

        default:
            return prevState
    }
}

export default ordersReducer
