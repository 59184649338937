import customerReducer from './customerReducer.ts'

import { ENUM_ACTION_TYPES } from '@/enums/Enums.ts'

import { ApplicationAction, InitTablePageDataPayload, TablePageState } from '@/types/globalTypes.js'

const tablePageReducer = (state: TablePageState, action: ApplicationAction): TablePageState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.INIT_DATA: {
            const payload = action.payload as InitTablePageDataPayload
            return { ...state, ...payload }
        }

        case ENUM_ACTION_TYPES.DELETE_CUSTOMER_RESERVATION:
        case ENUM_ACTION_TYPES.UPDATE_CUSTOMER_TABLE:
        case ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION:
        case ENUM_ACTION_TYPES.GET_CUSTOMER_ORDERS:
        case ENUM_ACTION_TYPES.ADD_ORDER:
        case ENUM_ACTION_TYPES.UPDATE_CUSTOMER_ORDER:
        case ENUM_ACTION_TYPES.DELETE_CUSTOMER_ORDER:
            return {
                ...state,
                ...customerReducer(state, action),
            }
        default:
            return state
    }
}

export default tablePageReducer
