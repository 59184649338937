import { ENUM_PATHNAMES, ENUM_ROLES } from '@/constants/constants.ts'

export const ROLE_TO_PATHNAMES = {
    [ENUM_ROLES.WAITER]: [ENUM_PATHNAMES.ORDERS_DASHBOARD],
    [ENUM_ROLES.BARMAN]: [ENUM_PATHNAMES.ORDERS_DASHBOARD],
    [ENUM_ROLES.HOST]: [ENUM_PATHNAMES.RESERVATIONS_DASHBOARD],
    [ENUM_ROLES.MANAGER]: [ENUM_PATHNAMES.ADMIN_DASHBOARD],
    [ENUM_ROLES.OWNER]: [ENUM_PATHNAMES.ADMIN_DASHBOARD],
    // Map other roles as needed...
}
