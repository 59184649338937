// Client
import { useMemo, useState } from 'react'
import { Provider } from 'react-wrap-balancer'

import supabaseBrowserClient from '@/lib/client'

/* Providers */
import { SpeedInsights } from '@vercel/speed-insights/next'

import { ThemeProvider, useTheme } from 'next-themes'
import dynamic from 'next/dynamic.js'

import './app.css'

import TranslationProvider from '@/i18n'

import ApplicationProvider from '@/context/ApplicationContext'
import ApplicationStoreProvider from '@/context/ApplicationStore'
import AuthProvider from '@/context/AuthContext'

/* Components */
import LoadingSpinner from '@/components/shared/LoadingSpinner/LoadingSpinner'

import { ToastNotificationT } from '@/types/globalTypes'

const Toaster = dynamic(() => import('react-hot-toast').then((c) => c.Toaster), {
    ssr: false,
})

function MyApp({ Component, pageProps }) {
    const { theme } = useTheme()
    const supabaseClient = useMemo(() => supabaseBrowserClient, [])

    const [loadingUser, setLoadingUser] = useState(true)
    const [venueLoading, setVenueLoading] = useState(true)

    const [toastNotification, setToastNotification] = useState<ToastNotificationT>({
        type: '',
        message: '',
        promise: null,
    })

    return (
        <Provider preferNative={false}>
            <TranslationProvider>
                <Toaster
                    containerClassName="!z-[999999999]"
                    toastOptions={{
                        className: `${typeof window !== 'undefined' && theme === 'dark' ? '!bg-slate-700' : '!bg-base-100'} !border !text-current !border-opacity-30 !border-slate-500 !font-semibold`,
                    }}
                />
                <AuthProvider
                    setLoadingUser={setLoadingUser}
                    supabaseClient={supabaseClient}
                    setToastNotification={setToastNotification}
                >
                    <ThemeProvider
                        enableColorScheme={true}
                        defaultTheme="dark"
                        disableTransitionOnChange={true}
                    >
                        <ApplicationProvider
                            setVenueLoading={setVenueLoading}
                            toastNotification={toastNotification}
                            setToastNotification={setToastNotification}
                        >
                            <ApplicationStoreProvider setToastNotification={setToastNotification} >
                                {loadingUser || venueLoading ? (
                                    <div className="flex justify-center w-screen h-screen">
                                        <LoadingSpinner className="m-auto" />
                                    </div>
                                ) : (
                                    <Component {...pageProps} />
                                )}
                                <SpeedInsights />
                            </ApplicationStoreProvider>
                        </ApplicationProvider>
                    </ThemeProvider>
                </AuthProvider>
            </TranslationProvider>
        </Provider>
    )
}

export default MyApp
