/* eslint-disable react-hooks/rules-of-hooks */
import {
    ApplicationAction,
    ExtendedEventsT,
    ExtendedReservationT,
    ExtendedTableT,
    ModifiedExtendedReservationT,
    ReservationCountT,
    ReservationsDashboardState,
    ReservationSubmitValuesТ,
    ReservationT,
    ToastNotificationT,
} from '@/types/globalTypes'
import { CustomLens } from '../store'
import { getInitialReservationsDashboardState, getInitialReservationState, getInitialSelectedTableState, updateState } from '@/utils/typeGuardUtils'
import { Setter } from '@dhmk/zustand-lens'
import { Row } from 'react-table'
import { ENUM_ACTION_TYPES } from '@/enums/Enums'
import { NOTIFICATION_TYPE, STATUS_CONSTANTS } from '@/constants/constants'

import useBookingServiceApi from '@/hooks/api/useBookingServiceApi'
import reservationsDashboardReducer from '../reducers/reservationsDashboardReducer'
import { formatDate } from '@/utils/calendarUtils'

// Define the slice state and actions
export type ReservationsSlice = {
    state: ReservationsDashboardState
    dispatch: (action: ApplicationAction) => void
    selectedReservation: ModifiedExtendedReservationT
    initialReservationState: ModifiedExtendedReservationT
    initialSelectedTableState: ExtendedTableT
    selectedTable: ExtendedTableT
    setSelectedReservation: Setter<ModifiedExtendedReservationT>
    setSelectedTable: Setter<ExtendedTableT>
    setShowTableComponent: Setter<boolean>
    setLockCurrentShift: Setter<boolean>
    setEvents: Setter<ExtendedEventsT[]>
    setSelectedEvents: Setter<ExtendedEventsT[]>
    setReservationsCountPerDay: Setter<ReservationCountT>
    setSelectedFlatRows: Setter<Row<ModifiedExtendedReservationT>[]>
    setLoading: Setter<boolean>
    setInitialPageLoad: Setter<boolean>,
    setSelectedDate: Setter<Date>;
    setMinDate: Setter<Date>;
    setMaxDate: Setter<Date>;
    setDateToday: Setter<Date>;
    setStartingHours: Setter<string>;
    setEndingHours: Setter<string>;
    handleCreateReservation: (values: ReservationSubmitValuesТ) => Promise<void>;
    handleUpdateReservation: (values: ReservationSubmitValuesТ) => Promise<void>;
    handleDeleteReservations: (ids: string[]) => Promise<void>;
    handleCancelReservation: (reservation: ModifiedExtendedReservationT) => Promise<void>;
    handleConfirmReservation: (reservation: ModifiedExtendedReservationT) => Promise<void>;
    handleCopyDefaultTableLayout: () => Promise<void>;
    handleCreateTable: (values: ExtendedTableT) => Promise<void>;
    handleEditTable: (tableData: ExtendedTableT) => Promise<void>;
    handleDeleteTable: (tableId: string) => Promise<void>;
    handleCreateWalkInReservation: (reservation: ExtendedReservationT) => Promise<void>
    handleReserveTable: (reservation: ExtendedReservationT, tableId: string) => Promise<void>
    handleDisableOrEnableAutoPinRenewal: (value: boolean) => Promise<void>
    handleGenerateNewPinCode: (static_reservation_id: string) => Promise<void>
    handleCompleteReservation: () => Promise<void>
}

const {
    copyDefaultTableLayout,
    createReservation,
    createTable,
    deleteTable,
    generateNewPinCode,
    updateReservation,
    updateTable,
    deleteMultipleReservations,
} = useBookingServiceApi()


export const reservationsDashboardSlice: CustomLens<ReservationsSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {

    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };

    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);

    const dispatch = (action: ApplicationAction) =>
        set((state) => ({
            ...state,
            state: reservationsDashboardReducer(state.state, action),
        }))


    const createSetter = <K extends keyof ReservationsSlice>(key: K) => (value: ReservationsSlice[K]) => set((state: ReservationsSlice) => ({
        ...state,
        [key]: value,
    }));
    const createNestedSetter = <K extends keyof ReservationsDashboardState>(key: K) => (value: ReservationsDashboardState[K]) => set((state: ReservationsSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));


    const resetSelectedTableAndReservation = (isReservationToBeRelocated = false) => {

        set((state) => ({
            selectedTable: state.initialSelectedTableState,

        }));

        if (isReservationToBeRelocated === false) {
            set((state) => ({
                selectedReservation: state.initialReservationState,
            }));

        }
    }

    const closeAllPopups = () => {
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openDrawer: false,
                    openAssignReservationToTablePopup: false,
                    openViewPopup: false,
                    isTableToBeReserved: false,
                    isReservationToBeRelocated: false,
                    openRemoveReservationFromTablePopup: false,
                    openMoveReservationToAnotherTablePopup: false,
                }
            },
        }));
    }


    const handleCreateReservation = async (values: ReservationSubmitValuesТ) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const reservationData = {
            id: values.id,
            name_of_the_person: values.fullName,
            email: values.email,
            phone_number: values.phone,
            date_of_reservation: values.date_of_reservation,
            start_time: values.start_time,
            end_time: values.end_time,
            people: values.people,
            status: values.status,
            extra_information: values.extra_information,
            is_temporary: values.is_temporary,
            venue_id: values.venue_id,
            created_by_role:values.created_by_role
        } as ReservationT;

        const [error, data] = await createReservation(reservationData);

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
        } else {
            dispatch({
                type: ENUM_ACTION_TYPES.INSERT_RESERVATION,
                payload: { newReservation: data[0] },
            });

            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        openCreateReservationPopup: false,
                    }
                },
            }));
            setToastNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
            });
        }
    };

    const handleUpdateReservation = async (values: ReservationSubmitValuesТ) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const reservationData = {
            id: values.id,
            name_of_the_person: values.fullName,
            email: values.email,
            phone_number: values.phone,
            date_of_reservation: values.date_of_reservation,
            people: values.people,
            status: values.status,
            extra_information: values.extra_information,
            start_time: values.start_time,
            end_time: values.end_time,
        };

        const { data, error } = await updateReservation(reservationData);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        });
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
        setGlobalState((state) => ({
            ...state,
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openEditReservationPopup: false,
                }
            },
        }));

    };

    const handleDeleteReservations = async (ids: string[]) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const error = await deleteMultipleReservations(ids);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_RESERVATIONS,
            payload: { ids },
        });
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
        setGlobalState((state) => ({
            ...state,
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openDeleteReservationPopup: false,
                }
            },
        }));
    };

    const handleCancelReservation = async (reservation: ModifiedExtendedReservationT) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const reservationData = {
            id: reservation.id,
            name_of_the_person: reservation.name_of_the_person,
            email: reservation.email,
            phone_number: reservation.phone_number,
            people: reservation.people,
            status: STATUS_CONSTANTS.DECLINED,
            extra_information: reservation.extra_information,
        };

        const { data, error } = await updateReservation(reservationData);

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        });
        setGlobalState((state) => ({
            ...state,
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCancelReservationPopup: false,
                }
            },
        }));
    };

    const handleConfirmReservation = async (reservation: ModifiedExtendedReservationT) => {
        const { data, error } = await updateReservation(reservation)

        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            return
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        })

        setGlobalState((state) => ({
            ...state,
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openEditReservationPopup: false,
                    openViewReservationPopup: false
                }
            },
        }))

    }


    const handleGenerateNewPinCode = async (static_reservation_id: string) => {
        setLoading(true);
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        const [data, error] = await generateNewPinCode(static_reservation_id);

        if (error) {

            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);

        } else {

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        id: state.selectedTable.static_reservation_id,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    },
                },
            }));
            setToastNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE || 'Success',
            });
            setLoading(false);
            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        openGenerateNewPinCode: false,

                    }
                },
            }));
        }
    };

    const handleCopyDefaultTableLayout = async () => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        const selectedDate = get().state.selectedDate;


        const { data, error } = await copyDefaultTableLayout(formatDate(selectedDate));

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        const typedData = data as ExtendedTableT[];

        dispatch({
            type: ENUM_ACTION_TYPES.COPY_DEFAULT_TABLE_LAYOUT,
            payload: { newTables: typedData },
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCopyDefaultTableLayoutPopup: false,
                }
            },
        }));

    };

    const handleCreateTable = async (values: ExtendedTableT) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        const selectedDate = get().state.selectedDate;
        const starting_hours = get().state.starting_hours;

        const tableData = {
            table_name: values.table_name,
            reserved: values.reserved,
            section_id: values.section_id,
            waiter: null,
            is_static: values.is_static,
            table_date: `${formatDate(selectedDate)}T${starting_hours}`,
            venue_id: values.venue_id,
        };

        const [data, error] = await createTable(tableData);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.INSERT_TABLE,
            payload: { newTable: data[0] },
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCreateTablePopup: false,
                }
            },
        }));
    };

    const handleEditTable = async (tableData: ExtendedTableT) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const [data, error] = await updateTable(tableData);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_TABLE,
            payload: { updatedTable: data[0] },
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openEditTablePopup: false,
                }
            },
        }));
        resetSelectedTableAndReservation();
    };

    const handleDeleteTable = async (tableId: string) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const error = await deleteTable(tableId);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_TABLE,
            payload: { deletedTableId: tableId },
        });
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openDeleteTablePopup: false,
                }
            },
        }));
        resetSelectedTableAndReservation();
    };

    const handleReserveTable = async (reservation: ExtendedReservationT, tableId: string) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const { pin_code, ...restOfData } = reservation;

        const updatedReservation = {
            ...restOfData,
            table: null,
            table_id: tableId,
        };

        const { error } = await updateReservation(updatedReservation);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            resetSelectedTableAndReservation();
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: { ...reservation, ...updatedReservation } },
        });
        resetSelectedTableAndReservation();
        closeAllPopups();
    };

    const handleCreateWalkInReservation = async (values: ExtendedReservationT) => {
        setLoading(true);
        const reservationData = {
            date_of_reservation: values.date_of_reservation,
            people: values.people,
            status: STATUS_CONSTANTS.CONFIRMED,
            extra_information: values.extra_information,
            start_time: values.start_time,
            end_time: values.end_time,
            is_temporary: true,
            is_walk_in: true,
            venue_id: values.venue_id
        } as ReservationT;

        const [createReservationError, data] = await createReservation(reservationData);

        if (createReservationError) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.INSERT_RESERVATION,
            payload: { newReservation: data[0] },
        });


        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCreateWalkInReservationPopup: false,
                }
            },
        }));
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
    };

    const handleCompleteReservation = async () => {
        setLoading(true);
        const { data, error } = await updateReservation({
            id: get().selectedReservation.id,
            status: STATUS_CONSTANTS.COMPLETED,
            waiter_responded: true,
            table_id: null
        });
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        if (error) {

            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        })

        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCompleteReservationFromTablePopup: false,
                    openViewPopup: false,
                }
            },
        }));
        resetSelectedTableAndReservation();
    };

    const handleDisableOrEnableAutoPinRenewal = async (value: boolean) => {

        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const { pin_code, ...restOfData } = get().selectedReservation;

        const { data, error } = await updateReservation({
            ...restOfData,
            skip_auto_pin_renewal: value,
        });

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }


        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        });

        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openAutoRenewalPinCodePopup: false,
                }
            },
        }));
    };



    return {
        state: getInitialReservationsDashboardState(),
        selectedReservation: getInitialReservationState(),
        selectedTable: getInitialSelectedTableState(),
        initialReservationState: getInitialReservationState(),
        initialSelectedTableState: getInitialSelectedTableState(),
        dispatch,
        setSelectedReservation: createSetter('selectedReservation'),
        setSelectedTable: createSetter('selectedTable'),
        setSelectedFlatRows: createNestedSetter('selectedFlatRows'),
        setReservationsCountPerDay: createNestedSetter('reservationsCountPerDay'),
        setEvents: createNestedSetter('events'),
        setShowTableComponent: createNestedSetter('showTableComponent'),
        setLockCurrentShift: createNestedSetter('lockCurrentShift'),
        setLoading: createNestedSetter('loading'),
        setInitialPageLoad: createNestedSetter('initialPageLoad'),
        setSelectedEvents: createNestedSetter('selectedEvents'),
        setSelectedDate: createNestedSetter('selectedDate'),
        setMinDate: createNestedSetter('minDate'),
        setMaxDate: createNestedSetter('maxDate'),
        setDateToday: createNestedSetter('dateToday'),
        setStartingHours: createNestedSetter('starting_hours'),
        setEndingHours: createNestedSetter('ending_hours'),
        handleCreateReservation,
        handleUpdateReservation,
        handleDeleteReservations,
        handleCancelReservation,
        handleConfirmReservation,
        handleGenerateNewPinCode,
        handleCopyDefaultTableLayout,
        handleCreateTable,
        handleDeleteTable,
        handleEditTable,
        handleReserveTable,
        handleDisableOrEnableAutoPinRenewal,
        handleCreateWalkInReservation,
        handleCompleteReservation,

    }
}
