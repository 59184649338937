
import { captureException } from '@sentry/nextjs'

import supabaseBrowserClient from '@/lib/client'
import { v4 as uuidv4 } from 'uuid'


import {
    Difference,
    ExtendedOptionalItemT,
    ExtendedOrderT,
    LocalizationT,
    MenuItemUpdateT,
    ModifiedExtendedMenuItemT,
    ModifiedExtendedOptionalItemT,
    OrderUpdateT,
    TFetchReceipt,
} from '@/types/globalTypes'

const useTableServiceApi = () => {
    const supabaseClient = supabaseBrowserClient

    const getCategories = async (locale: string) => {
        let { data, error } = await supabaseClient.rpc('get_event_menu_categories', {
            locale,
        })

        if (error) {
            captureException(error)
        }
        return { data, error }
    }

    const getCategoryItems = async (categoryId: string, locale: string) => {
        const { data, error } = await supabaseClient
            .from('menu_items_group_categories')
            .select(
                `
                *,
            menu_items(
              *,
              menu_items_localization (
                name,
                description,
                language_code
              )
            )
          `
            )
            .match({ category_id: categoryId, 'menu_items.is_active': true })
            .in('menu_items.menu_items_localization.language_code', [locale, 'en'])

        if (error) {
            captureException(error)
        }

        if (data) {
            const flattenedData = data
                .map((itemGroup) => {
                    if (!itemGroup.menu_items) return null // Return null if menu_items is not present

                    const menuItem = itemGroup.menu_items

                    let localization: LocalizationT = menuItem.menu_items_localization.find(
                        (loc) => loc.language_code === locale
                    )

                    // If not found, use the English localization as a fallback.
                    if (!localization) {
                        localization =
                            menuItem.menu_items_localization.find(
                                (loc) => loc.language_code === 'en'
                            ) || {}
                    }

                    return {
                        ...menuItem,
                        name: localization.name,
                        description: localization.description,
                        menu_items_localization: null, // Optionally remove the original field
                    }
                })
                .filter((item) => item !== null) // Remove null values after mapping

            return { data: flattenedData, error }
        }

        return { data: null, error }
    }

    const getMenuItemLocale = async (menuItemId: string, locale: string) => {
        const { data, error } = await supabaseClient
            .from('menu_items')
            .select(
                `
            menu_items_localization (
              name,
              description,
              language_code
            )
          `
            )
            .eq('id', menuItemId)
            .in('menu_items_localization.language_code', [locale, 'en'])

        if (error) {
            captureException(error)
            return { data: null, error }
        }

        if (data && data.length > 0 && data[0].menu_items_localization) {
            data[0].menu_items_localization.sort((a, b) => {
                if (a.language_code === locale) return -1
                if (b.language_code === locale) return 1
                return 0
            })
        }

        return { data: data[0], error }
    }

    const getMenuItemNutrition = async (menuItemId: string) => {
        const { data, error } = await supabaseClient
            .from('menu_item_nutrition')
            .select('*')
            .eq('menu_item_id', menuItemId)

        if (error) {
            captureException(error)
            return { data: null, error }
        }

        return { data: data[0], error }
    }
    const getMultipleMenuItemsLocale = async (ids: string[], locale: string) => {
        const { data, error } = await supabaseClient
            .from('menu_items_localization')
            .select('*')
            .in('language_code', [locale, 'en'])
            .in('item_id', ids)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const getCustomerOrdersById = async (reservation_id: string) => {
        let { data: orderData, error: orderError } = await supabaseClient
            .from('orders')
            .select('*')
            .eq('reservation_id', reservation_id)

        if (orderError) {
            captureException(orderError)
        }

        return { orderData, orderError }
    }

    const getCustomerOrdersByGuestId = async (guest_id: string) => {
        if (!guest_id) {
            // Immediately return empty data and no error if guest_id is undefined
            return { orderData: [], orderError: null }
        }

        let { data: orderData, error: orderError } = await supabaseClient
            .from('orders')
            .select('*')
            .eq('guest_id', guest_id)

        if (orderError) {
            captureException(orderError)
        }

        return { orderData, orderError }
    }

    const getMenuItemOptions = async (menuItemId: string, locale: string) => {
        const { data, error } = await supabaseClient
            .from('menu_item_option_groups')
            .select(`
                *,
            option_groups (
              *,
              optional_menu_items_group:optional_menu_items_group(*),
              option_groups_localization: option_groups_localization (
               *
              ),
              option_group_items: menu_items (
                *,
                option_items_localization: menu_items_localization (
                  *
                )
              )
            )
          `
            )
            .eq('menu_item_id', menuItemId)
            .in('option_groups.option_groups_localization.language_code', [locale, 'en'])
            .in('option_groups.option_group_items.menu_items_localization.language_code', [
                locale,
                'en',
            ])

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const searchMenuItems = async (itemName: string, locale: string) => {
        const { data, error } = await supabaseClient.rpc('search_menu_items', {
            item_name: itemName,
            p_language_code: locale,
        })

        if (data) {
            const mappedData = data.map((item) => ({ ...item, id: item.item_id }))
            return { data: mappedData, error }
        }
        if (error) {
            captureException(error)
        }
        return { data: null, error }
    }

    const searchMenuItemsInMenuPreview = async (
        itemName: string,
        locale: string,
        category_ids: string[]
    ) => {
        const { data, error } = await supabaseClient.rpc(
            'preview_menu_items_by_category_and_name',
            {
                item_name: itemName,
                p_language_code: locale,
                p_category_ids: category_ids,
            }
        )

        if (data) {
            const mappedData = data.map((item) => ({ ...item, id: item.item_id }))
            return { data: mappedData, error }
        }
        if (error) {
            captureException(error)
        }
        return { data: null, error }
    }

    const fetchReceiptDetails = async (receiptId: string) => {
        let { data, error } = await supabaseClient.rpc('fetch_receipt_and_order_details', {
            _receipt_id: receiptId,
        })

        if (error) {
            captureException(error)
        }

        return { data: data as TFetchReceipt, error }
    }
    const fetchHistoryReceiptDetails = async (receiptId: string) => {
        let { data, error } = await supabaseClient.rpc('fetch_receipt_and_order_history_details', {
            _receipt_id: receiptId,
        })
        if (error) {
            captureException(error)
        }
        return { data: data as TFetchReceipt, error }
    }

    const getCurrentShiftOrders = async (
        dateOne: string,
        dateTwo: string,
        offset = 0,
        limit = 20
    ) => {
        let { data: orders, error } = await supabaseClient
            .from('orders')
            .select()
            .lte('created_at', dateTwo)
            .gte('created_at', dateOne)
            .range(offset, offset + limit - 1)
            .order('created_at', { ascending: false })

        if (error) {
            captureException(error)
        }

        return { orders, ordersError: error }
    }

    const getSpecificOrders = async (
        reservationIds: string[],
        dateOne: string,
        dateTwo: string
    ) => {
        let { data: orders, error } = await supabaseClient
            .from('orders')
            .select()
            .in('reservation_id', reservationIds)
            .lte('created_at', dateTwo)
            .gte('created_at', dateOne)

        if (error) {
            captureException(error)
        }

        return { orders, ordersError: error }
    }

    const searchOrders = async (
        searchValue: string,
        startDate: string,
        endDate: string,
        status: string
    ) => {
        let { data, error } = await supabaseClient.rpc('search_orders', {
            _date_one: startDate,
            _date_two: endDate,
            _search_value: searchValue,
            _status: status,
        })

        if (error) {
            captureException(error)
        }
        return { data, error }
    }

    const assignTableToWaiter = async (waiterId: string, waiterName: string, tableId: string) => {
        const { error } = await supabaseClient
            .from('venue_tables')
            .update({ waiter: waiterId, waiter_name: waiterName })
            .eq('id', tableId)

        if (error) {
            captureException(error)
        }
        return error
    }

    const unassignTableFromWaiter = async (tableId: string) => {
        const { error } = await supabaseClient
            .from('venue_tables')
            .update({ waiter: null, waiter_name: null })
            .eq('id', tableId)

        if (error) {
            captureException(error)
        }

        return error
    }

    const fetchOrder = async (orderId: string) => {
        const { data, error } = await supabaseClient.rpc('fetch_order_with_details', {
            _order_id: orderId,
        })

        if (error) {
            captureException(error)
            console.error('Error fetching order with details:', error)
            return { data: null, error }
        }

        return { data, error }
    }

    const createOrder = async (order: ExtendedOrderT): Promise<[ExtendedOrderT | null, any]> => {
        const totalItemsQuantity = order.order_items.reduce((acc, item) => acc + item.quantity, 0)

        // Since restOfOrderDetails doesn't include order_items directly, it represents _order_details for the RPC call
        const restOfOrderDetails: Omit<
            ExtendedOrderT,
            'order_items' | 'reservation' | 'buttons'
        > & { total_items_quantity: number } = {
            ...order,
            total_items_quantity: totalItemsQuantity,
        }

        // Prepare order_items for bulk insert
        const orderItems: ModifiedExtendedMenuItemT[] = order.order_items.map((item) => ({
            ...item,
            menu_item_id: item.id,
            quantity: item.quantity,
            price: item.price,
            unique_id: item.unique_id,
            promotion: item.promotion,
        }))

        // Assuming options need to be prepared similarly, though your original object has options as an empty array
        const orderItemOptions: ModifiedExtendedOptionalItemT[] = order.order_items.flatMap(
            (item) =>
                item.options.map((option) => ({
                    ...option,
                    unique_order_id: order.id, // Use the order id from restOfOrder
                    unique_option_item_id: uuidv4(),
                    option_item_id: option.id,
                    option_group_id: option.option_group_id,
                    quantity: option.quantity,
                    price: option.price,
                    promotion: option.promotion,
                    optional_menu_items_group_id: option.optional_menu_items_group_id,
                    unique_menu_item_id: item.unique_id,
                }))
        )

        // Call the SQL function with the prepared parameters
        const { data, error } = await supabaseClient.rpc('create_order', {
            _order_details: restOfOrderDetails,
            _order_items: orderItems,
            _order_item_options: orderItemOptions,
        })

        if (error) {
            captureException(error)
            console.error('Error creating order:', error)
            return [null, error]
        }

        // Assuming the SQL function returns an object with these keys; adjust as necessary
        const createdOrder: ExtendedOrderT = {
            ...order,
            total_items_quantity: totalItemsQuantity,
            id: data[0].returned_order_id,
            order_number: data[0].returned_order_number, // This field needs to be added to ExtendedOrderT if not already present
            created_at: data[0].returned_created_at, // Adjust ExtendedOrderT to include this if necessary
        }

        return [createdOrder, null]
    }

    const updateOrderAndOrderItems = async (
        order: Partial<ExtendedOrderT>,
        initialOrderItemsState: ModifiedExtendedMenuItemT[] | null = null
    ) => {
        const { id: orderId, order_items, buttons, ...restOfOrder } = order

        // Preparing the order details for the update
        const orderDetails = {
            ...restOfOrder,
            id: orderId,
        }

        // Preparing the order item options for the update, considering both initial and current states
        const initialOptionItemsState = initialOrderItemsState.flatMap((item) =>
            item.options.map((option) => {
                return {
                    unique_order_id: orderId,
                    unique_option_item_id: option.unique_option_item_id,
                    option_item_id: option.id || option.option_item_id,
                    option_group_id: option.option_group_id || option.optionGroupId,
                    quantity: option.quantity,
                    price: option.price,
                    optional_menu_items_group_id: option.optional_menu_items_group_id,
                    unique_menu_item_id: item.unique_id,
                }
            })
        )

        const orderItemOptions = order_items.flatMap((item) =>
            item.options.map((option) => {
                return {
                    ...option,
                    unique_order_id: orderId,
                    unique_option_item_id: option.unique_option_item_id || uuidv4(),
                    option_item_id: option.id || option.option_item_id,
                    option_group_id: option.option_group_id || option.optionGroupId,
                    quantity: option.quantity,
                    price: option.price,
                    optional_menu_items_group_id: option.optional_menu_items_group_id,
                    unique_menu_item_id: item.unique_id,
                }
            })
        )

        // Calling the SQL function with prepared parameters
        const { data, error } = await supabaseClient.rpc('update_order', {
            _order_id: orderId,
            _order_details: orderDetails,
            _order_items: order_items,
            _order_item_options: orderItemOptions,
            _initial_order_items_state: initialOrderItemsState,
            _initial_option_items_state: initialOptionItemsState,
        })

        if (error) {
            captureException(error)
            console.error('Error updating order:', error)
            return { data: null, orderError: error }
        }
        const updatedOrderData = data[0].updated_order as unknown as ExtendedOrderT
        const updatedItems = data[0].updated_order_items as ModifiedExtendedMenuItemT[]
        const updatedOptionalItems = data[0].updated_order_item_options as ExtendedOptionalItemT[]

        const updatedOrder: ExtendedOrderT = {
            ...updatedOrderData,
            total_price: order.total_price,
            order_items: updatedItems,
            order_item_options: updatedOptionalItems,
        }

        return { orderData: updatedOrder, orderError: error }
    }

    const updateOrder = async (order: OrderUpdateT) => {
        const { data, error } = await supabaseClient
            .from('orders')
            .update({ ...order })
            .eq('id', order.id)
            .select()

        if (error) {
            captureException(error)
            return { orderData: data, orderError: error }
        }
        return { orderData: data[0], orderError: error }
    }

    const updateOrders = async (ids: string[], update: OrderUpdateT) => {
        const { data, error } = await supabaseClient
            .from('orders')
            .update(update)
            .in('id', ids)
            .select()

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const updateInventoryItem = async (itemId: string, update: MenuItemUpdateT) => {
        const { data, error } = await supabaseClient
            .from('menu_items')
            .update(update)
            .eq('id', itemId)
            .select()

        if (error) {
            captureException(error)
            return [data, error]
        }
        return [data[0], error]
    }

    const updateInventoryOptionItems = async (items: Difference[], venueId: string) => {
        const { data, error } = await supabaseClient
            .from('menu_items')
            .upsert({ ...items, venue_id: venueId }, { onConflict: 'id' })

        if (error) {
            captureException(error)
        }
        return [data, error]
    }

    const objectApi = {
        getCategories,
        getCategoryItems,
        getCustomerOrdersById,
        getCustomerOrdersByGuestId,
        getMenuItemOptions,
        getMenuItemLocale,
        getMenuItemNutrition,
        getMultipleMenuItemsLocale,
        searchOrders,
        searchMenuItems,
        searchMenuItemsInMenuPreview,
        getCurrentShiftOrders,
        getSpecificOrders,
        assignTableToWaiter,
        unassignTableFromWaiter,
        fetchOrder,
        createOrder,
        updateOrderAndOrderItems,
        updateOrder,
        updateOrders,
        updateInventoryItem,
        updateInventoryOptionItems,
        fetchReceiptDetails,
        fetchHistoryReceiptDetails,
    }

    return objectApi
}

export default useTableServiceApi
