import { ENUM_ACTION_TYPES } from '@/enums/Enums.ts'

import {
    ApplicationAction,
    ExtendedReservationT,
    ExtendedTableT,
    TableState,
} from '@/types/globalTypes'

const tablesReducer = (prevState: TableState, action: ApplicationAction): TableState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.INSERT_TABLE: {
            const { newTable } = action.payload
            const tableIndex = prevState.tablesData.findIndex((table) => table.id === newTable.id)

            // If the table is found return prevState to prevent duplicate
            if (tableIndex !== -1) {
                return prevState
            }
            // If the table is not found map check if with a tied reservation\

            if (tableIndex == -1) {
                const filteredReservation = prevState.reservations.find(
                    (reservation) =>
                        (reservation.table !== null && reservation.table.id === newTable.id) ||
                        newTable.static_reservation_id === reservation.id
                )

                const updatedTableData = filteredReservation
                    ? { ...newTable, reservation: filteredReservation }
                    : {
                          ...newTable,
                          reservation: {
                              id: '',
                              email: '',
                              extra_information: '',
                              name_of_the_person: '',
                              people: 0,
                              phone_number: '',
                              status: '',
                              is_temporary: false,
                              is_walk_in: false,
                              actions: '',
                              table: {
                                  floor: '',
                                  id: '',
                                  order: '',
                                  qrCode: '',
                                  reservation: {},
                                  reserved: '',
                                  section: '',
                                  table_name: '',
                              },
                          },
                      }

                // Create a new array with the updated table
                const updatedTables = [...prevState.tablesData, updatedTableData as ExtendedTableT]

                return { ...prevState, tablesData: updatedTables }
            }

            return prevState
        }

        case ENUM_ACTION_TYPES.UPDATE_TABLE: {
            const { updatedTable } = action.payload

            // Find the index of the table using the table's id
            const tableIndex = prevState.tablesData.findIndex(
                (table) => table.id === updatedTable.id
            )

            // If the table is found, update it with the new data
            if (tableIndex !== -1) {
                const filteredReservation = prevState.reservations.find(
                    (reservation) =>
                        (reservation.table !== null && reservation.table.id === updatedTable.id) ||
                        updatedTable.static_reservation_id === reservation.id
                )

                const updatedTableData = filteredReservation
                    ? { ...updatedTable, reservation: filteredReservation }
                    : {
                          ...updatedTable,
                          reservation: {
                              id: '',
                              email: '',
                              extra_information: '',
                              name_of_the_person: '',
                              people: 0,
                              phone_number: '',
                              status: '',
                              is_temporary: false,
                              is_walk_in: false,
                              actions: '',
                              table: {
                                  floor: '',
                                  id: '',
                                  order: '',
                                  qrCode: '',
                                  reservation: {},
                                  reserved: '',
                                  section: '',
                                  table_name: '',
                              },
                          },
                      }

                // Create a new array with the updated table
                const updatedTables = [...prevState.tablesData]
                updatedTables[tableIndex] = updatedTableData as ExtendedTableT

                return { ...prevState, tablesData: updatedTables }
            }

            return prevState
        }

        case ENUM_ACTION_TYPES.DELETE_TABLE: {
            const { deletedTableId } = action.payload

            // Remove the table from the tables array
            const updatedTables = prevState.tablesData.filter(
                (table) => table.id !== deletedTableId
            )

            // Update reservations that were associated with the deleted table
            const updatedReservations = prevState.reservations.map((reservation) => {
                if (reservation.table !== null && reservation.table.id === deletedTableId) {
                    return {
                        ...reservation,
                        table: {
                            floor: '',
                            id: '',
                            order: '',
                            qrCode: '',
                            reservation: {},
                            reserved: '',
                            section: '',
                            table_name: '',
                        },
                    }
                }
                return reservation
            }) as ExtendedReservationT[]

            return { ...prevState, tablesData: updatedTables, reservations: updatedReservations }
        }

        case ENUM_ACTION_TYPES.COPY_DEFAULT_TABLE_LAYOUT: {
            const { newTables } = action.payload

            const updatedTables = [...prevState.tablesData]

            newTables.forEach((tableData) => {
                const tableExists = updatedTables.some((table) => table.id === tableData.id)

                if (!tableExists) {
                    updatedTables.push({
                        ...tableData,
                        floor: '',
                        section: '',
                        reservation: {
                            id: '',
                            venue_id:'',
                            email: '',
                            extra_information: '',
                            name_of_the_person: '',
                            people: 0,
                            phone_number: '',
                            status: '',
                            is_temporary: false,
                            is_walk_in: false,
                            actions: '',
                            agreed_to_terms: false,
                            created_at: '',
                            date_of_reservation: '',
                            end_time: '',
                            guest_id: '',
                            is_static: false,
                            start_time: '',
                            waiter_responded: false,
                            hasPendingOrder: false,
                            skip_auto_pin_renewal: false,
                            table_id: '',
                            pin_code: '',
                            table: {
                                ...(tableData as ExtendedTableT),
                            },
                        },
                    })
                }
            })

            return { ...prevState, tablesData: updatedTables }
        }

        // other cases...

        default:
            return prevState
    }
}

export default tablesReducer
