import { formatDate } from './calendarUtils.ts'
import { getDefaultExtendedReservationStructure } from './typeGuardUtils.ts'
import { addDays, setHours, setMinutes, setSeconds, subDays } from 'date-fns'

import { NextRouter } from 'next/router.js'

import { BUCKET_URL, DELTA_TYPES, ENUM_PATHNAMES, ENUM_ROLES } from '@/constants/constants.ts'

import {
    Difference,
    ExtendedCategoryT,
    ExtendedEventsT,
    ExtendedFloorLocalizationT,
    ExtendedMenuItemT,
    ExtendedNotificationT,
    ExtendedReservationT,
    ExtendedSectionLocalizationT,
    ExtendedTableT,
    FieldNameT,
    FloorLocalizationT,
    FloorPlanMap,
    FloorTablesBySections,
    InventoryState,
    LanguageOptionTab,
    LocalizationT,
    MenuCategoryT,
    MenuItemLocalizationT,
    ModifiedExtendedCategoryT,
    ModifiedExtendedMenuItemT,
    ModifiedExtendedOptionalItemT,
    OrderT,
    SectionLocalizationT,
    TLanguageOption,
    TMappedLocalization,
    TabT,
    TableT,
    UnmappedOptionGroupsT,
} from '@/types/globalTypes.js'

export function debounce<F extends (...args: any[]) => void>(
    func: F,
    delay: number
): (...args: Parameters<F>) => void | Promise<void> {
    let timeoutId: NodeJS.Timeout | null = null
    return (...args: Parameters<F>) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }
}

export const getLocale = (selectedLanguage: string) => {
    return `${selectedLanguage}-${selectedLanguage.toUpperCase}`
}

export const formatCurrency = (price: number, currency = 'BGN', locale = 'de-DE', showZero = true) => {
    // Check if the price is 0 and the showZero flag is false to return an empty string or another placeholder
    if (price === 0 && !showZero) {
        return ""; // or return something like "--" or "Select options"
    }

    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(price);
}



export const calculateTotalItemPrice = (
    menuItem: ModifiedExtendedMenuItemT,
    withCurrency = true,
    quantity = 1,
    currency = 'BGN'
) => {
    // Calculate base price
    let basePrice = menuItem.price

    // If there's a promotion, calculate the promotional price for the main item
    if (menuItem.promotion) {
        basePrice = basePrice * (1 - menuItem.promotion / 100)
    }

    // Calculate options price including promotions
    let optionsPrice = 0
    if (menuItem.options) {
        for (let option of menuItem.options) {
            // Check if the option has a promotion and apply it
            let optionPrice = option.price
            if (option.promotion) {
                optionPrice = optionPrice * (1 - option.promotion / 100)
            }
            optionsPrice += optionPrice * option.quantity
        }
    }

    // Calculate total price
    let totalPrice = (basePrice + optionsPrice) * quantity

    // Format the total price as currency if required, or return it fixed to 2 decimal places
    return withCurrency ? formatCurrency(totalPrice, currency, 'de-DE', true) : totalPrice.toFixed(2)
}

export const calculateTotalOptionalItemPrice = (
    optionItem: ModifiedExtendedOptionalItemT,
    withCurrency = true,
    quantity = 1,
    currency = 'BGN'
) => {
    // Calculate base price
    let basePrice = optionItem.price

    // If there's a promotion, calculate the promotional price
    if (optionItem.promotion) {
        basePrice = basePrice * (1 - optionItem.promotion / 100)
    }
    // Calculate total price
    let totalPrice = basePrice * quantity

    return withCurrency ? formatCurrency(totalPrice, currency) : totalPrice.toFixed(2)
}

export const calculateTotalPrice = <T extends boolean>(
    selectedItems: ModifiedExtendedMenuItemT[],
    withCurrency: T = true as T,
    currency = 'BGN'
): T extends true ? string : number => {
    const totalPrice = selectedItems.reduce((total, item) => {
        // Calculate the discounted price for the main item
        const discountedPrice = item.promotion
            ? item.price - (item.promotion / 100) * item.price
            : item.price

        let optionsPrice = 0
        if (item.options) {
            // Calculate the total price for options, including any promotions
            optionsPrice = item.options.reduce((optTotal, option) => {
                const discountedOptionPrice = option.promotion
                    ? option.price - (option.promotion / 100) * option.price
                    : option.price
                return optTotal + discountedOptionPrice * option.quantity
            }, 0)
        }

        // Add the price of the main item and its options, then multiply by item quantity
        return total + (discountedPrice + optionsPrice) * item.quantity
    }, 0)

    // Format the total price as a currency if required
    if (withCurrency) {
        // Return string when withCurrency is true
        return formatCurrency(totalPrice, currency, 'de-DE', true) as T extends true ? string : number
    } else {
        // Return number when withCurrency is false
        // Use Number instead of toFixed(2) to return a number instead of a string
        return Number(totalPrice.toFixed(2)) as T extends true ? string : number
    }
}

export const calculateTotalItemsQuantity = (items: ModifiedExtendedMenuItemT[]) => {
    if (items !== undefined && items !== null) {
        return items.reduce((total, item) => total + item.quantity, 0)
    }
}

/*     It first compares the lengths of the two top-level arrays. If they are not equal, it returns false, indicating that the arrays are not equal and the order has been changed.

    If the lengths of the top-level arrays are equal, it then loops over each item in these arrays. For each item, it compares the values of the id, quantity, name, and price fields. If any of these values do not match, it returns false.

    If an item has an options array, it then compares the lengths of these options arrays. If they are not equal, it returns false.

    If the lengths of the options arrays are equal, it then loops over each item in these arrays. For each item, it compares the values of the optionItemId, quantity, name, and price fields. If any of these values do not match, it returns false.
     */
export const isOrderChanged = (
    arr1: ModifiedExtendedMenuItemT[],
    arr2: ModifiedExtendedMenuItemT[]
) => {
    // Sort the arrays by unique_id
    arr1.sort((a, b) => a.unique_id.localeCompare(b.unique_id))
    arr2.sort((a, b) => a.unique_id.localeCompare(b.unique_id))

    if (arr1.length !== arr2.length) {
        return false
    }

    for (let i = 0; i < arr1.length; i++) {
        // check the unique_id and menu_item_id/id fields
        if (
            arr1[i].unique_id !== arr2[i].unique_id ||
            arr1[i].menu_item_id !== arr2[i].id ||
            arr1[i].quantity !== arr2[i].quantity ||
            arr1[i].name !== arr2[i].name ||
            arr1[i].price !== arr2[i].price
        ) {
            return false
        }

        if (arr1[i].options && arr2[i].options) {
            // Sort the options by optionItemId
            arr1[i].options.sort((a, b) => a.menu_item_number - b.menu_item_number)
            arr2[i].options.sort((a, b) => a.menu_item_number - b.menu_item_number)

            if (arr1[i].options.length !== arr2[i].options.length) {
                return false
            }

            for (let j = 0; j < arr1[i].options.length; j++) {
                if (
                    arr1[i].options[j].optionItemId !== arr2[i].options[j].optionItemId ||
                    arr1[i].options[j].quantity !== arr2[i].options[j].quantity ||
                    arr1[i].options[j].name !== arr2[i].options[j].name ||
                    arr1[i].options[j].price !== arr2[i].options[j].price
                ) {
                    return false
                }
            }
        } else if (arr1[i].options || arr2[i].options) {
            // One has options but the other doesn't
            return false
        }
    }

    return true
}

export const isCategoryOrderChanged = (
    arr1: ExtendedCategoryT[] | MenuCategoryT[],
    arr2: ExtendedCategoryT[] | MenuCategoryT[]
) => {
    if (arr1.length !== arr2.length) return false

    // Sort both arrays based on the category_id before comparing
    const sortedArr1 = arr1.toSorted(
        (a: MenuCategoryT | ExtendedCategoryT, b: MenuCategoryT | ExtendedCategoryT) =>
            a.order_number - b.order_number
    )
    const sortedArr2 = arr2.toSorted(
        (a: MenuCategoryT | ExtendedCategoryT, b: MenuCategoryT | ExtendedCategoryT) =>
            a.order_number - b.order_number
    )

    for (let i = 0; i < sortedArr1.length; i++) {
        if (
            sortedArr1[i].category_id !== sortedArr2[i].category_id ||
            sortedArr1[i].order_number !== sortedArr2[i].order_number
        ) {
            return false
        }
    }
    return true
}

export const calculateQueryDates = (
    dateToday: Date,
    currentTime: string,
    starting_hours: string,
    ending_hours: string,
    includeTime: boolean
) => {
    const currentUnixTime = new Date(`1970-01-01T${currentTime}Z`).getTime() / 1000
    const startingUnixTime = new Date(`1970-01-01T${starting_hours}Z`).getTime() / 1000
    const endingUnixTime = new Date(`1970-01-01T${ending_hours}Z`).getTime() / 1000
    const midnight = new Date(`1970-01-01T00:00:00Z`).getTime() / 1000

    let queryStartDate: string, queryEndDate: string

    if (endingUnixTime <= startingUnixTime) {
        if (currentUnixTime > midnight && currentUnixTime > endingUnixTime) {
            queryStartDate = formatDate(dateToday, includeTime, starting_hours)
            queryEndDate = formatDate(addDays(dateToday, 1), true, ending_hours)
        } else {
            queryStartDate = formatDate(subDays(dateToday, 1), includeTime, starting_hours)
            queryEndDate = formatDate(dateToday, includeTime, ending_hours)
        }
    } else {
        queryStartDate = formatDate(dateToday, includeTime, starting_hours)
        queryEndDate = formatDate(dateToday, includeTime, ending_hours)
    }

    return [queryStartDate, queryEndDate]
}

export const calculateQueryDatesWithoutCurrentTime = (
    selectedDate: Date,
    starting_hours: string,
    ending_hours: string
) => {
    const endingUnixTime = new Date(`1970-01-01T${ending_hours}Z`).getTime() / 1000
    const startingUnixTime = new Date(`1970-01-01T${starting_hours}Z`).getTime() / 1000
    let queryStartDate: Date
    let queryEndDate: Date

    const [startingHours, startingMinutes, startingSeconds] = starting_hours.split(':').map(Number)
    const [endingHours, endingMinutes, endingSeconds] = ending_hours.split(':').map(Number)

    if (endingUnixTime <= startingUnixTime) {
        queryStartDate = selectedDate
        queryEndDate = addDays(selectedDate, 1)
    } else {
        queryStartDate = selectedDate
        queryEndDate = selectedDate
    }

    let updatedCurrentShiftStartDate = setHours(queryStartDate, startingHours)
    updatedCurrentShiftStartDate = setMinutes(updatedCurrentShiftStartDate, startingMinutes)
    updatedCurrentShiftStartDate = setSeconds(updatedCurrentShiftStartDate, startingSeconds)

    let updatedCurrentShiftEndDate = setHours(queryEndDate, endingHours)
    updatedCurrentShiftEndDate = setMinutes(updatedCurrentShiftEndDate, endingMinutes)
    updatedCurrentShiftEndDate = setSeconds(updatedCurrentShiftEndDate, endingSeconds)

    return [updatedCurrentShiftStartDate, updatedCurrentShiftEndDate]
}

export const getStockLevelColor = (inventory: number, maxInventory: number) => {
    const percentage = (inventory / maxInventory) * 100

    if (percentage < 30) {
        return 'text-red-500'
    } else if (percentage < 70) {
        return 'text-yellow-500'
    } else {
        return 'text-green-500'
    }
}

export const getStockLevelColorBar = (inventory: number, maxInventory: number) => {
    const percentage = (inventory / maxInventory) * 100

    if (percentage < 30) {
        return 'bg-red-500'
    } else if (percentage < 70) {
        return 'bg-yellow-500'
    } else {
        return 'bg-green-500'
    }
}

export const compareObjects = <T extends Record<string, any>>(obj1: T, obj2: T): boolean => {
    // Check if both arguments are objects
    if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
        const keys1 = Object.keys(obj1)
        const keys2 = Object.keys(obj2)

        if (keys1.length !== keys2.length) {
            return false
        }

        for (const key of keys1) {
            if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                if (!compareObjects(obj1[key], obj2[key])) {
                    return false
                }
            } else if (obj1[key] !== obj2[key]) {
                return false
            }
        }

        return true
    }

    return obj1 === obj2
}

export const extractDifferences = (obj1: InventoryState, obj2: InventoryState): Difference[] => {
    const differences: Difference[] = []

    function compareAndExtract(
        obj1: InventoryState | number,
        obj2: InventoryState | number,
        currentPath: string
    ): void {
        // Assuming compareObjects is a function that can compare both numbers and InventoryRecord objects.
        // You'll need to implement or specify this function according to your needs.
        if (typeof obj1 === 'object' && typeof obj2 === 'object' && compareObjects(obj1, obj2)) {
            return
        }

        if (typeof obj1 !== 'object' && typeof obj2 !== 'object') {
            if (obj1 !== obj2) {
                differences.push({ id: currentPath, inventory: Number(obj2) })
            }
            return
        }

        if (typeof obj1 === 'object' && typeof obj2 === 'object') {
            const keys2 = Object.keys(obj2)

            for (const key of keys2) {
                const newPath = key
                const value1 = obj1[key]
                const value2 = obj2[key]

                compareAndExtract(value1 as any, value2 as any, newPath)
            }
        }
    }

    compareAndExtract(obj1, obj2, '')
    return differences
}

/* Remove the unused information in the future such as reservation object and etc */
export const mapNotification = (
    notification: ExtendedNotificationT,
    orders: OrderT[],
    reservations: ExtendedReservationT[],
    tables: TableT[]
) => {
    const order = orders.find((order) => order.id === notification.entity_id)
    const reservation = reservations.find((reservation) => reservation.id === order?.reservation_id)

    /* We use the old_entity_id to find the table that was corresponding at the time of
  the order because we store in that column when the event happens */
    const oldTable = tables.find((table) => table.id === notification.old_entity_id)
    const newTable = tables.find((table) => table.id === notification.entity_id)

    // Always map order, reservation, and table info onto the notification
    let mappedNotification: ExtendedNotificationT = {
        ...notification,
        order: order,
        reservation: reservation,
        oldTableName: oldTable?.table_name,
        newTableName: newTable?.table_name,
        read_notifications: notification.read_notifications ? notification.read_notifications : [],
    }

    return mappedNotification
}

/* This function is used when we query the menu item names and desc in a certain language
and based on the query param for locale we send to the DB, we also use english as a fallback*/
export const remapSelectedMenuItems = (
    menuItems: ModifiedExtendedMenuItemT[],
    menuItemsLocaleData: MenuItemLocalizationT[],
    optionalItemsLocaleData: MenuItemLocalizationT[],
    selectedLanguage: string
) => {
    return menuItems.map((item) => {
        // Using optional chaining and nullish coalescing to provide a fallback
        const itemName =
            menuItemsLocaleData.find(
                (locale) =>
                    locale.item_id === item.menu_item_id &&
                    locale.language_code === selectedLanguage
            )?.name ??
            menuItemsLocaleData.find(
                (locale) => locale.item_id === item.menu_item_id && locale.language_code === 'en'
            )?.name ??
            'Default Name' // Fallback name if neither localization is found

        const itemDescription =
            menuItemsLocaleData.find(
                (locale) =>
                    locale.item_id === item.menu_item_id &&
                    locale.language_code === selectedLanguage
            )?.description ??
            menuItemsLocaleData.find(
                (locale) => locale.item_id === item.menu_item_id && locale.language_code === 'en'
            )?.description ??
            'Default Description' // Fallback description

        return {
            ...item,
            name: itemName,
            description: itemDescription,
            options: item.options.map((optionalItem) => {
                const optionItemName =
                    optionalItemsLocaleData.find(
                        (locale) =>
                            locale.item_id === optionalItem.optionItemId &&
                            locale.language_code === selectedLanguage
                    )?.name ??
                    optionalItemsLocaleData.find(
                        (locale) =>
                            locale.item_id === optionalItem.optionItemId &&
                            locale.language_code === 'en'
                    )?.name ??
                    'Default Option Name' // Fallback name for options

                const optionItemDescription =
                    optionalItemsLocaleData.find(
                        (locale) =>
                            locale.item_id === optionalItem.optionItemId &&
                            locale.language_code === selectedLanguage
                    )?.description ??
                    optionalItemsLocaleData.find(
                        (locale) =>
                            locale.item_id === optionalItem.optionItemId &&
                            locale.language_code === 'en'
                    )?.description ??
                    'Default Option Description' // Fallback description for options

                return {
                    ...optionalItem,
                    name: optionItemName,
                    description: optionItemDescription,
                }
            }),
        }
    })
}

export const getRoutesForRoles = (roles: string[], ROLE_ROUTES: { [x: string]: string[] }) => {
    let routes: string[] = []

    roles.forEach((role) => {
        if (ROLE_ROUTES[role]) {
            routes = [...routes, ...ROLE_ROUTES[role]]
        }
    })

    // Remove duplicates if any
    return [...new Set(routes)]
}

export const userHasAccessToRoute = (
    roles: string[],
    pathname: string,
    ROLE_TO_PATHNAMES: {
        [x: string]: string[]
    }
) => {
    for (let role of roles) {
        if (ROLE_TO_PATHNAMES[role] && ROLE_TO_PATHNAMES[role].includes(pathname)) {
            return true
        }
    }
    return false
}

export const createFloorTabs = (
    floorTablesBySections: FloorTablesBySections,
    className: string
) => {
    const defaultClassName = 'drop-shadow tab tab-lg'
    const tabsArray: TabT[] = []
    let index = 0
    for (const key of Object.keys(floorTablesBySections)) {
        tabsArray.push({
            id: key,
            label: key,
            className: className || defaultClassName,
        })
        index++
    }
    return tabsArray.sort((a, b) => a.label.localeCompare(b.label))
}

export const mapInitialRenderStateForSections = (
    floorTablesBySections: FloorTablesBySections,
    floorTabs: TabT[]
) => {
    let filteredTables: { [key: string]: boolean } = {}

    floorTabs.forEach((tab) => {
        const floor = floorTablesBySections[tab.label]
        const floorName = tab.label
        for (const section in floor) {
            const sectionKey = `${floorName}-${section}`
            filteredTables[sectionKey] = true
        }
    })

    return filteredTables
}

export const initializeFloorPlanMapRenderState = (floorPlan: FloorPlanMap) => {
    const initialState: { [key: string]: boolean } = {}
    Object.keys(floorPlan).forEach((floorId) => {
        initialState[floorId] = true
    })
    return initialState
}

export const getTranslation = (
    translations: FloorLocalizationT[] | SectionLocalizationT[],
    locale: string
) => {
    return (
        translations.find((local) => local.language_code === locale) ||
        translations.find((local) => local.language_code === 'en')
    ) // fallback to "en"
}

export const createFloorPlanMap = (
    floors: ExtendedFloorLocalizationT[],
    sections: ExtendedSectionLocalizationT[],
    locale: string
) => {
    const floorPlanMap: FloorPlanMap = {}

    // Sort floors alphabetically based on their translations in the selected locale
    floors.sort((a, b) => {
        const translationA = getTranslation(a.floors_localization, locale)
        const translationB = getTranslation(b.floors_localization, locale)

        if (translationA && translationB) {
            return translationA.translated_name.localeCompare(translationB.translated_name)
        }
        return 0 // If one or both translations are missing, don't change their order
    })

    // First, map out the floors with their translated names
    floors.forEach((floor) => {
        const translation = getTranslation(floor.floors_localization, locale)
        if (translation) {
            floorPlanMap[floor.id] = {
                floor: translation.translated_name,
                sections: {}, // Prepare an empty sections object for each floor
            }
        }
    })

    // Sort sections alphabetically based on their translations in the selected locale
    sections.sort((a, b) => {
        const translationA = getTranslation(a.sections_localization, locale)
        const translationB = getTranslation(b.sections_localization, locale)

        if (translationA && translationB) {
            return translationA.translated_name.localeCompare(translationB.translated_name)
        }
        return 0 // If one or both translations are missing, don't change their order
    })

    // Now, populate the sections inside their respective floors
    sections.forEach((section) => {
        const floorEntry = floorPlanMap[section.floor_id]
        const translation = getTranslation(section.sections_localization, locale)
        if (floorEntry && translation) {
            floorEntry.sections[section.id] = translation.translated_name
        }
    })

    return floorPlanMap
}

export const getFloorTablesBySectionsV2 = (
    tables: ExtendedTableT[],
    floorPlanMap: FloorPlanMap
): FloorTablesBySections => {
    const floorTablesBySections: FloorTablesBySections = {}

    tables.forEach((table) => {
        const section_id = table?.section_id

        // Finding the corresponding floor based on the section_id
        const floorID = Object.keys(floorPlanMap).find((floorID) =>
            Boolean(floorPlanMap[floorID].sections[section_id])
        )

        // If we don't find a matching floorID, we skip the current table
        if (!floorID) {
            return
        }

        const floorName = floorPlanMap[floorID].floor
        const sectionName = floorPlanMap[floorID].sections[section_id]

        floorTablesBySections[floorName] = floorTablesBySections[floorName] || {}
        floorTablesBySections[floorName][sectionName] =
            floorTablesBySections[floorName][sectionName] || []
        floorTablesBySections[floorName][sectionName].push(table)
    })

    return floorTablesBySections
}

export const calculateMenuStyles = (
    position: { x: number; y: number },
    parentRef: React.RefObject<HTMLElement>,
    top: string = '-35%'
): {
    menuPositionStyle: { left?: string; top: string; right?: string }
    animationClass: string
} => {
    const isMiddle = position.x > window.innerWidth / 3 && position.x < (window.innerWidth / 3) * 2
    const hasNoSiblings =
        parentRef.current &&
        parentRef.current.parentElement &&
        parentRef.current.parentElement.lastChild &&
        parentRef.current.parentElement.lastChild.previousSibling !== null
    const isLastChild =
        parentRef.current &&
        parentRef.current.parentElement &&
        parentRef.current.parentElement.lastChild === parentRef.current &&
        hasNoSiblings

    let menuPositionStyle: { left?: string; top: string; right?: string }
    let animationClass: string

    if (isLastChild || position.x > window.innerWidth / 2) {
        menuPositionStyle = { left: '-105%', top }
        animationClass = 'animate-fade-left'
    } else if (isMiddle) {
        menuPositionStyle = { top, left: '105%', right: '0%' }
        animationClass = 'animate-fade-right'
    } else {
        menuPositionStyle = { left: '105%', top }
        animationClass = 'animate-fade-right'
    }

    return { menuPositionStyle, animationClass }
}

export const updateFloorsState = (
    currentFloors: ExtendedFloorLocalizationT[],
    newLocalizations: FloorLocalizationT[]
) => {
    // Assuming newLocalizations is never an empty array
    const newFloorId = newLocalizations[0].floor_id

    // Check if the floor already exists
    const existingFloorIndex = currentFloors.findIndex((floor) => floor.id === newFloorId)

    // If the floor doesn't exist, add it along with its localizations
    if (existingFloorIndex === -1) {
        return [
            ...currentFloors,
            {
                id: newFloorId,
                floors_localization: newLocalizations,
            },
        ]
    }

    // If the floor exists, update its localizations
    const existingFloor = currentFloors[existingFloorIndex]
    newLocalizations.forEach((newLocalization) => {
        const existingLocalizationIndex = existingFloor.floors_localization.findIndex(
            (localization) => localization.language_code === newLocalization.language_code
        )

        // If the localization doesn't exist for the floor, add it
        if (existingLocalizationIndex === -1) {
            existingFloor.floors_localization.push(newLocalization)
        } else {
            // If the localization exists for the floor, update it
            existingFloor.floors_localization[existingLocalizationIndex] = newLocalization
        }
    })

    // Construct the updated floors array
    const updatedFloors = [...currentFloors]
    updatedFloors[existingFloorIndex] = existingFloor

    return updatedFloors
}

export const updateSectionsState = (
    currentSections: ExtendedSectionLocalizationT[],
    newSectionLocalizations: SectionLocalizationT[]
) => {
    // Assuming newSectionLocalizations is never an empty array
    const newSectionId = newSectionLocalizations[0].section_id
    const associatedFloorId = newSectionLocalizations[0].floor_id // assuming all the localizations for a section have the same floor_id

    // Check if the section already exists
    const existingSectionIndex = currentSections.findIndex((section) => section.id === newSectionId)

    // If the section doesn't exist, add it along with its localizations
    if (existingSectionIndex === -1) {
        return [
            ...currentSections,
            {
                id: newSectionId,
                floor_id: associatedFloorId,
                sections_localization: newSectionLocalizations,
            },
        ]
    }

    // If the section exists, update its localizations and floor_id
    const existingSection = currentSections[existingSectionIndex]
    newSectionLocalizations.forEach((newLocalization) => {
        const existingLocalizationIndex = existingSection.sections_localization.findIndex(
            (localization) => localization.language_code === newLocalization.language_code
        )

        // If the localization doesn't exist for the section, add it
        if (existingLocalizationIndex === -1) {
            existingSection.sections_localization.push(newLocalization)
        } else {
            // If the localization exists for the section, update it
            existingSection.sections_localization[existingLocalizationIndex] = newLocalization
        }
    })
    existingSection.floor_id = associatedFloorId // update the floor_id

    // Construct the updated sections array
    const updatedSections = [...currentSections]
    updatedSections[existingSectionIndex] = existingSection

    return updatedSections
}

export const getLocalizedTableData = (
    table: TableT,
    sections: ExtendedSectionLocalizationT[],
    floors: ExtendedFloorLocalizationT[],
    selectedLanguage: string
): ExtendedTableT => {
    const section = sections.find((s) => s.id === table.section_id)

    if (!section)
        return {
            ...table,
            floor: '',
            section: '',
        }

    const floor = floors.find((f) => f.id === section.floor_id)
    if (!floor)
        return {
            ...table,
            floor: '',
            section: '',
        }

    const sectionLocalization = section.sections_localization.find(
        (localization) => localization.language_code === selectedLanguage
    ) ||
        section.sections_localization.find(
            (localization) => localization.language_code === 'en'
        ) || { translated_name: '' }

    const floorLocalization = floor.floors_localization.find(
        (localization) => localization.language_code === selectedLanguage
    ) ||
        floor.floors_localization.find((localization) => localization.language_code === 'en') || {
        translated_name: '',
    }

    return {
        ...table,
        section: sectionLocalization.translated_name,
        floor: floorLocalization.translated_name,
    }
}

export const formatCategoryData = (
    category: ModifiedExtendedCategoryT,
    selectedLanguage: string
) => {
    // Transform the dictionary format to an array of localizations
    const localizations = Object.entries(category.categories_localization).map(
        ([langCode, localization]) => ({
            ...localization,
            language_code: langCode,
            category_id: category.id,
        })
    )

    // Get label based on selected language or fallback to 'en'
    const selectedLabel =
        category.categories_localization[selectedLanguage]?.label ||
        category.categories_localization['en']?.label

    return {
        ...category,
        categories_localization: localizations,
        label: selectedLabel,
    }
}

export const getNewImageUrl = (fileName: string, bucket: string) =>
    `${process.env.NEXT_PUBLIC_SUPABASE_URL}/${BUCKET_URL.PUBLIC}/${bucket}/${fileName}`

export const areAllLanguagesFilled = (
    objectToValidate: LanguageOptionTab,
    fieldName: string,
    languageOptions: TLanguageOption[]
) => {
    for (let language of languageOptions) {
        const langCode = language.value.toLowerCase()
        if (!objectToValidate[langCode] || !objectToValidate[langCode][fieldName]) {
            return false // Language not filled
        }
    }
    return true
}

export const getUnfilledLanguages = (
    objectToValidate: TMappedLocalization,
    fieldName: string,
    languageOptions: TLanguageOption[]
) => {
    const unfilledLanguages: string[] = []
    for (let language of languageOptions) {
        const langCode = language.value.toLowerCase()
        if (!objectToValidate[langCode] || !objectToValidate[langCode][fieldName]) {
            unfilledLanguages.push(langCode) // Language not filled
        }
    }
    return unfilledLanguages
}
export const handleNumericKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.which

    const ARROW_LEFT = 37
    const ARROW_RIGHT = 39
    const ARROW_UP = 38
    const ARROW_DOWN = 40

    if (
        (charCode < 48 || charCode > 57) &&
        charCode !== 190 &&
        charCode !== 8 &&
        charCode !== ARROW_LEFT &&
        charCode !== ARROW_RIGHT &&
        charCode !== ARROW_UP &&
        charCode !== ARROW_DOWN
    ) {
        event.preventDefault()
    }

    if (charCode === 190 && event.currentTarget.value.indexOf('.') !== -1) {
        event.preventDefault()
    }
}

export const handleNumericWholeNumberKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ARROW_LEFT = 37
    const ARROW_RIGHT = 39
    const ARROW_UP = 38
    const ARROW_DOWN = 40
    const charCode = event.which

    const isNumberKey = charCode >= 48 && charCode <= 57
    const isArrowKey = charCode >= ARROW_LEFT && charCode <= ARROW_DOWN
    const isBackspaceKey = charCode === 8

    if (!(isNumberKey || isArrowKey || isBackspaceKey)) {
        event.preventDefault()
    }
}
export const initializeLocalization = (
    languages: { id: string }[],
    fields = ['name', 'description']
) => {
    const initialLocalization: TMappedLocalization = {}
    languages.forEach((language) => {
        initialLocalization[language.id.toLowerCase()] = Object.fromEntries(
            fields.map((field) => [field, ''])
        )
    })
    return initialLocalization
}

export const updateLocalization = (
    existingLocalization: TMappedLocalization,
    languages: { id: string }[],
    fields: string[]
) => {
    const updatedLocalization: TMappedLocalization = { ...existingLocalization }

    languages.forEach((lang) => {
        const langId = lang.id.toLowerCase()

        if (!updatedLocalization[langId]) {
            updatedLocalization[langId] = {}
            fields.forEach((field) => {
                updatedLocalization[langId][field] = '' // initialize with empty string
            })
        }
    })

    return updatedLocalization
}

export const transformCategoryLocalization = (localizationData: LocalizationT[]) => {
    const mappedLocalizationData: TMappedLocalization = {}
    localizationData.forEach((loc) => {
        mappedLocalizationData[loc.language_code] = {
            ...loc,
            name: loc.label,
            description: loc.description,
        }
    })

    return mappedLocalizationData
}

export const transformMenuItemLocalization = (
    localizationData: LocalizationT[]
): TMappedLocalization => {
    const mappedLocalizationData: TMappedLocalization = {}
    localizationData.forEach((loc) => {
        mappedLocalizationData[loc.language_code] = {
            ...loc,
            name: loc.name,
            description: loc.description,
        }
    })

    return mappedLocalizationData
}
export const transformOptionGroupLocalization = (localizationData: LocalizationT[]) => {
    const mappedLocalizationData: TMappedLocalization = {}
    localizationData.forEach((loc) => {
        mappedLocalizationData[loc.language_code] = {
            ...loc,
            name: loc.name,
            description: loc.instruction,
        }
    })

    return mappedLocalizationData
}

export const flattenMenuItemData = (
    data: ExtendedMenuItemT[],
    locale: string,
    categories: ExtendedCategoryT[]
) => {
    return data
        .map((menuItem) => {
            if (!menuItem.menu_items_localization) return null

            let localization = menuItem.menu_items_localization.find(
                (loc) => loc.language_code === locale
            )

            // If not found, use the English localization as a fallback.
            if (!localization) {
                localization =
                    menuItem.menu_items_localization.find((loc) => loc.language_code === 'en') || {}
            }

            const menuItemsGroupCategoriesWithLabels = menuItem.menu_items_group_categories
                ? menuItem.menu_items_group_categories.map((cat) => {
                    const categoryLabel =
                        categories.find((category) => category.id === cat.category_id)?.label ||
                        ''
                    return { ...cat, id: cat.category_id, label: categoryLabel }
                })
                : []

            return {
                ...menuItem,
                venue_id: menuItem.venue_id,
                menu_items_localization: transformMenuItemLocalization(
                    menuItem.menu_items_localization
                ),
                name: localization.name,
                description: localization.description,
                menu_items_group_categories: menuItemsGroupCategoriesWithLabels,
            }
        })
        .filter((item) => item !== null) // Remove null values after mapping
}

export const getLocalization = (
    localizations: LocalizationT[],
    selectedLanguageCode: string,
    fields: FieldNameT[]
) => {
    let localization =
        localizations.find((loc) => loc.language_code === selectedLanguageCode) ||
        localizations.find((loc) => loc.language_code === 'en') ||
        {}
    let mappedLocalization: LocalizationT = {}
    fields.forEach((field) => {
        mappedLocalization[field] = localization[field] || ''
    })

    return mappedLocalization
}

export const mapGroups = (groups: UnmappedOptionGroupsT[], selectedLanguageCode: string) => {
    return groups.map((group) => {
        const groupLocalization = getLocalization(
            group.option_groups_localization,
            selectedLanguageCode,
            ['name', 'instruction']
        )

        let optional_menu_items_group_id = ''

        // Create a map for easy lookup of optional item ids to uuids
        const optionalItemIdToUuidMap = Object.fromEntries(
            group.optional_menu_items_group
                ? group.optional_menu_items_group.map((item) => [
                    item.optional_item_id,
                    item.optional_menu_items_group_id,
                ])
                : []
        )
        // Check if group.option_group_items is null or undefined before mapping over it
        const optionItems = (group.option_group_items ? group.option_group_items : []).map(
            (item) => {
                const itemLocalization = getLocalization(
                    item.option_items_localization,
                    selectedLanguageCode,
                    ['name', 'description']
                )

                optional_menu_items_group_id = optionalItemIdToUuidMap[item.id]
                const uuid = optionalItemIdToUuidMap[item.id] // Look up the uuid using the item id

                return {
                    ...item,
                    optionItemId: item.id,
                    price: item.price,
                    optional_menu_items_group_id: uuid,
                    option_items_localization: transformMenuItemLocalization(
                        item.option_items_localization
                    ),
                    availability: item.availability,
                    ...itemLocalization,
                }
            }
        )

        return {
            venue_id: group.venue_id,
            option_group_number: group.option_group_number,
            singleSelection: group.single_selection,
            optionGroupId: group.id,
            id: group.id,
            maxSelections: group.max_selections,
            minSelections: group.min_selections,
            required: group.required,
            optional_menu_items_group_id,
            optionItems: optionItems, // This will now be an empty array if source.option_group_items is null or undefined
            instruction: groupLocalization.instruction,
            label: groupLocalization.instruction,
            option_groups_localization: transformOptionGroupLocalization(
                group.option_groups_localization
            ),
        }
    })
}

export const flattenEventData = (events: ExtendedEventsT[]) => {
    return events.map((event) => {
        const flattenedEventMenus = event.event_menus.map((eventMenu) => {
            return {
                ...eventMenu,
                ...eventMenu.menus, // This spreads the properties from `menus` into `eventMenu`
            }
        })

        return {
            ...event,
            event_menus: flattenedEventMenus,
        }
    })
}

export const mapSelectedItems = (items: ModifiedExtendedMenuItemT[]) => {
    return items.map((item) => ({
        id: item.id,
        menu_item_id: item.id,
        quantity: item.quantity,
        price: item.price,
        unique_id: item.unique_id,
        promotion: item.promotion,
        options: item.options.map((option) => ({
            id: option.optionItemId,
            option_item_id: option.optionItemId,
            option_group_id: option.optionGroupId,
            quantity: option.quantity,
            price: option.price,
            promotion: option.promotion,
            unique_option_item_id: option.unique_option_item_id,
            optional_menu_items_group_id: option.optional_menu_items_group_id,
        })),
    }))
}

export const mergeOrders = (prev: OrderT[], newOrders: OrderT[]) => {
    const updatedOrders = [...prev]

    newOrders.forEach((newOrder) => {
        const index = updatedOrders.findIndex((order) => order.id === newOrder.id)

        if (index !== -1) {
            // Update existing order
            updatedOrders[index] = newOrder
        } else {
            // Insert new order
            updatedOrders.push(newOrder)
        }
    })

    return updatedOrders
}

export const calculateDeltaPercentage = (current: number, previous: number) => {
    if (previous === 0 || previous === undefined || previous === null) {
        return 0 // Or return 0 if you prefer to represent no change as 0%
    }
    return ((current - previous) / previous) * 100
}

export const getDeltaType = (delta: number) => {
    if (delta > 10) return DELTA_TYPES.INCREASE
    if (delta <= 10 && delta > 0) return DELTA_TYPES.MODERATE_INCREASE
    if (delta < 0) return DELTA_TYPES.DECREASE
    return DELTA_TYPES.NEUTRAL
}

export const redirectToDashboard = async (role: string, router: NextRouter) => {
    if (role === ENUM_ROLES.HOST) {
        await router.push(ENUM_PATHNAMES.RESERVATIONS_DASHBOARD)
    } else if (role === ENUM_ROLES.WAITER || role === ENUM_ROLES.BARMAN) {
        await router.push(ENUM_PATHNAMES.ORDERS_DASHBOARD)
    } else if (role === ENUM_ROLES.MANAGER || role === ENUM_ROLES.OWNER) {
        await router.push(ENUM_PATHNAMES.ADMIN_DASHBOARD)
    } else {
        await router.push(ENUM_PATHNAMES.LOGIN)
    }
}

export const updateTables = (
    tablesData: ExtendedTableT[],
    updatedReservation: ExtendedReservationT,
    hasPendingOrder: boolean
) => {
    // Deep copy to prevent direct state mutation
    let updatedTables = structuredClone(tablesData)

    // Adjustments to ensure table ID is consistently used
    const tableId =
        typeof updatedReservation.table === 'object'
            ? updatedReservation.table.id
            : updatedReservation.table_id

    // Find the indexes for easier manipulation
    const oldTableIndex = updatedTables.findIndex(
        (table) =>
            table.reservation?.id === updatedReservation.id ||
            table.static_reservation_id === updatedReservation.id
    )
    const newTableIndex = updatedTables.findIndex(
        (table) => table.id === tableId || table.static_reservation_id === updatedReservation.id
    )

    // Scenario where reservation moves to a different table
    if (oldTableIndex !== -1 && newTableIndex !== -1 && oldTableIndex !== newTableIndex) {
        // Clear old table reservation

        updatedTables[oldTableIndex].reservation = getDefaultExtendedReservationStructure()
        // Assign to new table
        updatedTables[newTableIndex].reservation = { ...updatedReservation, hasPendingOrder }
        // Scenario where the reservation is updated but not moved to a new table
    } else if (oldTableIndex !== -1 && newTableIndex !== -1 && oldTableIndex === newTableIndex) {
        updatedTables[oldTableIndex].reservation = { ...updatedReservation, hasPendingOrder }

        // Scenario where a reservation is newly assigned to a table
    } else if (oldTableIndex === -1 && newTableIndex !== -1) {
        updatedTables[newTableIndex].reservation = { ...updatedReservation, hasPendingOrder }
        // Scenario where we remove the reservation from the table
    } else if (oldTableIndex !== -1 && newTableIndex === -1 && (tableId === '' || tableId === null)) {
        updatedTables[oldTableIndex].reservation = getDefaultExtendedReservationStructure()
    }
    // No action needed if oldTableIndex === -1 && newTableIndex === -1
    // as it implies the reservation isn't currently associated with any table, nor is it being added to a new one.

    return updatedTables
}
