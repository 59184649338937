import {
    addDays,
    differenceInMinutes,
    endOfMonth,
    isAfter,
    isBefore,
    isEqual,
    startOfMonth,
    subDays,
} from 'date-fns'

import { MappedWorkingHoursT, WorkingHoursT } from '@/types/globalTypes'

export const formatDate = (date: Date, includeTime = false, time = '00:00:00.000Z') => {
    const yyyy = date.getFullYear()
    let mm: string | number = date.getMonth() + 1 // Months start at 0!
    let dd: string | number = date.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    let formattedDate: string

    if (includeTime) {
        formattedDate = `${yyyy}-${mm}-${dd} ${time}`
    } else {
        formattedDate = `${yyyy}-${mm}-${dd}`
    }
    return formattedDate
}

export const formatDateToString = (date: Date) => {
    return date.toISOString().split('T')[0] + ' ' + date.toTimeString().split(' ')[0]
}

export const formatDateStringRemoveTimestamp = (dateString: string) => {
    const splittedStringDate = dateString.split('T')
    const formatedStringDate = splittedStringDate[0]

    return formatedStringDate
}

export const getTimeFromStringHoursMinutes = (dateString: string) => {
    const date = new Date(dateString)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    const currentTime = `${hours}:${minutes}`
    return currentTime
}
export const splitTimeStringIntoHoursAndMinutes = (timeString: string, includeSeconds = false) => {
    const [hours, minutes, seconds] = timeString.split(':')

    return includeSeconds ? `${hours}:${minutes}:${seconds}` : `${hours}:${minutes}`
}

export const setDateHoursMinutesSeconds = (date: Date, timeStamp: number[]) => {
    if (!timeStamp) return date

    const [hours, minutes, seconds] = timeStamp
    let adjustedDate = new Date(date)
    adjustedDate.setHours(hours)
    adjustedDate.setMinutes(minutes)
    adjustedDate.setSeconds(seconds)

    return adjustedDate
}

export const convertTimeStringToNumbers = (str: string) => {
    if (!str) {
        return [Number(0), Number(0), Number(0)]
    }

    const [hours, minutes, seconds] = str.split(':')

    return [Number(hours), Number(minutes), Number(seconds)]
}

export const compareDates = (dateLeft: Date, dateRight: Date) => {
    return isEqual(dateLeft, dateRight)
}

export const disableDateBeforeCurrent = (currentDate: Date, date: Date) => {
    return isBefore(date, currentDate)
}

export const getEndOfTheCurrentMonth = (date: Date) => {
    return endOfMonth(date)
}
export const getBeginningOfTheCurrentMonth = (date: Date) => {
    return startOfMonth(date)
}
export const normalizeDate = (d: Date) => new Date(d.setHours(0, 0, 0, 0))

export const timeSince = (date: Date, TIME_SINCE_TEMPLATE) => {
    const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000)
    let interval = Math.floor(seconds / 31536000) // calculate the number of years

    if (interval > 1) {
        return TIME_SINCE_TEMPLATE.YEARS_PLURAL(interval)
    }
    if (interval === 1) {
        return TIME_SINCE_TEMPLATE.YEARS_SINGULAR(interval)
    }
    interval = Math.floor(seconds / 2592000) // calculate the number of months
    if (interval > 1) {
        return TIME_SINCE_TEMPLATE.MONTHS_PLURAL(interval)
    }
    if (interval === 1) {
        return TIME_SINCE_TEMPLATE.MONTHS_SINGULAR(interval)
    }
    interval = Math.floor(seconds / 86400) // calculate the number of days
    if (interval > 1) {
        return TIME_SINCE_TEMPLATE.DAYS_PLURAL(interval)
    }
    if (interval === 1) {
        return TIME_SINCE_TEMPLATE.DAYS_SINGULAR(interval)
    }
    interval = Math.floor(seconds / 3600) // calculate the number of hours
    if (interval > 1) {
        return TIME_SINCE_TEMPLATE.HOURS_PLURAL(interval)
    }
    if (interval === 1) {
        return TIME_SINCE_TEMPLATE.HOURS_SINGULAR(interval)
    }
    interval = Math.floor(seconds / 60) // calculate the number of minutes
    if (interval > 1) {
        return TIME_SINCE_TEMPLATE.MINUTES_PLURAL(interval)
    }
    if (interval === 1) {
        return TIME_SINCE_TEMPLATE.MINUTES_SINGULAR(interval)
    }
    interval = seconds // calculate the number of seconds
    if (interval > 1) {
        return TIME_SINCE_TEMPLATE.SECONDS_PLURAL(interval)
    }
    if (interval === 1) {
        return TIME_SINCE_TEMPLATE.SECONDS_SINGULAR(interval)
    }
    return TIME_SINCE_TEMPLATE.JUST_NOW // if less than a second
}

export const findNextActiveDay = (startDate: Date, working_hours: MappedWorkingHoursT) => {
    let nextDate = startDate

    // Get the current time
    const currentTime = new Date()

    // Iterate through the next 7 days to find the next active day
    for (let i = 0; i <= 6; i++) {
        const dayOfWeek = nextDate.getDay()
        const currentDayWorkingHours = working_hours[dayOfWeek]

        // Check if the day is active
        if (currentDayWorkingHours && currentDayWorkingHours.is_active) {
            // Parse the starting and ending time of the working hours
            const [startingHours, startingMinutes] = currentDayWorkingHours.starting_hours
                .split(':')
                .map(Number)
            const [endingHours, endingMinutes] = currentDayWorkingHours.ending_hours
                .split(':')
                .map(Number)

            // Create a Date object for the times on this day
            const startingTime = new Date(nextDate)
            const endingTime = new Date(nextDate)
            endingTime.setHours(endingHours, endingMinutes, 0, 0)
            startingTime.setHours(startingHours, startingMinutes, 0, 0)

            // If the current time is after the ending time or before the starting time, this day's shift is already over or hasn't started yet, so continue to the next day
            if (isBefore(currentTime, startingTime)) {
                return nextDate
            } else if (isAfter(currentTime, endingTime)) {
                nextDate = addDays(nextDate, 1)
            }
            return nextDate
        }
        nextDate = addDays(nextDate, 1)
    }

    // If no active day is found within the next week, return startDate or handle this case separately
    return startDate
}

export const findLastActiveDay = (startDate: Date, working_hours: MappedWorkingHoursT) => {
    let prevDate = startDate

    // Get the current time
    const currentTime = new Date()

    // Iterate through the last 7 days to find the last active day
    for (let i = 0; i <= 6; i++) {
        prevDate = subDays(prevDate, 1) // Use subDays to go backwards
        const dayOfWeek = prevDate.getDay()
        const currentDayWorkingHours = working_hours[dayOfWeek]

        // Check if the day is active
        if (currentDayWorkingHours && currentDayWorkingHours.is_active) {
            // Parse the starting and ending time of the working hours
            const [startingHours, startingMinutes] = currentDayWorkingHours.starting_hours
                .split(':')
                .map(Number)
            const [endingHours, endingMinutes] = currentDayWorkingHours.ending_hours
                .split(':')
                .map(Number)

            // Create a Date object for the times on this day
            const startingTime = new Date(prevDate)
            const endingTime = new Date(prevDate)
            endingTime.setHours(endingHours, endingMinutes, 0, 0)
            startingTime.setHours(startingHours, startingMinutes, 0, 0)

            // If the current time is before the starting time, this day's shift hasn't started yet, so continue to the previous day
            if (isBefore(currentTime, startingTime)) {
                continue
            }

            // This day is active and the current time is before the ending time, so return this date
            return prevDate
        }
    }

    // If no active day is found within the last week, return startDate or handle this case separately
    return startDate
}

export const findNextAvailableBookingDay = (
    startDate: Date,
    working_hours: MappedWorkingHoursT,
    reservation_lead_time: string
) => {
    let nextDate = startDate

    // Convert reservation_lead_time to minutes
    // Assuming reservation_lead_time is in the format 'HH:MM:SS'
    const [hours, minutes] = reservation_lead_time.split(':').map(Number)
    const leadTimeInMinutes = hours * 60 + minutes

    // Get the current time
    const currentTime = new Date()

    // Iterate through the next 7 days to find the next available day
    for (let i = 0; i <= 6; i++) {
        const dayOfWeek = nextDate.getDay()
        const currentDayWorkingHours = working_hours[dayOfWeek]

        // Check if the day is active
        if (currentDayWorkingHours && currentDayWorkingHours.is_active) {
            // Parse the starting and ending time of the working hours
            const [startingHours, startingMinutes] = currentDayWorkingHours.starting_hours
                .split(':')
                .map(Number)

            // Create a Date object for the times on this day
            const startingTime = new Date(nextDate)
            startingTime.setHours(startingHours, startingMinutes, 0, 0)

            // If the current time is before the starting time minus the lead time, return this date
            if (differenceInMinutes(startingTime, currentTime) >= leadTimeInMinutes) {
                return nextDate
            }
        }

        nextDate = addDays(nextDate, 1) // Move to the next day and continue the loop
    }

    // If no available day is found within the next week, return startDate or handle this case separately
    return startDate
}

// Helper function to get the starting time of the next shift
export const getNextShiftStartTime = (date: Date, working_hours: WorkingHoursT) => {
    const dayOfWeek = date.getDay()
    const currentDayWorkingHours = working_hours[dayOfWeek]
    if (currentDayWorkingHours && currentDayWorkingHours.is_active) {
        const [startingHours, startingMinutes] = currentDayWorkingHours.starting_hours
            .split(':')
            .map(Number)
        const startingTime = new Date(date)
        startingTime.setHours(startingHours, startingMinutes, 0, 0)
        return startingTime
    }
    return null
}
