import React from 'react'

import { useGetTranslations } from '@/i18n/index.tsx'

const NotAuthorizedPageComponent = () => {
    const { NOT_AUTHORIZED_PAGE_TEXT } = useGetTranslations()

    return (
        <div className="flex flex-col w-screen h-screen m-auto text-center animate-fade animate-duration-1000">
            <div className="m-auto">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-16 h-16 mx-auto"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                </svg>
                <h1 className="text-5xl font-bold">{NOT_AUTHORIZED_PAGE_TEXT.NOT_AUTHORIZED}</h1>
            </div>
        </div>
    )
}

export default NotAuthorizedPageComponent
